import * as React from 'react';
import { FiDatabase } from "react-icons/fi";
import { ConteantHeadline } from '../../services/config';
import TestDB from './TestDB';

interface DatabaseProps{
}

const Database = (p: DatabaseProps) => {
    
return (
<>
    <ConteantHeadline>Datatenbank Test <FiDatabase style={{float: "left", marginTop: "0.6em", marginRight: 10}} /></ConteantHeadline>
    <TestDB />
</>
);
}
export default Database;