import styled from "styled-components";
import {
  BasicSettings,
  ButtonSettings,
  ButtonSettingsActive,
  ButtonSettingsActiveDanger,
  ButtonSettingsActiveSuccess,
  ButtonSettingsDanger,
  ButtonSettingsHover,
  ButtonSettingsHoverDanger,
  ButtonSettingsHoverSuccess,
  ButtonSettingsSuccess,
} from "../../services/config";

export const Container = styled.div<{
  isfluid: boolean;
  isactive: boolean;
  floated?: "left" | "right";
  iconOnly?: boolean;
  isdisabled?: boolean;
  type?: "success" | "danger";
  
}>`
  margin: 0;
  height: 40px;
  line-height: 40px;
  text-align: ${(p) => (p.isfluid ? "left" : "center")};
  float: ${(p) => p.floated ?? "unset"};
  padding: 0 ${(p) => (p.iconOnly ? 13 : 20)}px;
  border: 1px solid
    ${(p) =>
      p.isdisabled
        ? ButtonSettings.backgroundColor
        : p.type === "danger"
        ? ButtonSettingsDanger.borderColor
        : p.type === "success"
        ? ButtonSettingsSuccess.borderColor
        : p.isactive
        ? ButtonSettingsActive.borderColor
        : ButtonSettings.borderColor};
  background: ${(p) =>
    p.isdisabled
      ? BasicSettings.contentBackgroundColor
      : p.type === "danger"
      ? ButtonSettingsDanger.backgroundColor
      : p.type === "success"
      ? ButtonSettingsSuccess.backgroundColor
      : p.isactive
      ? ButtonSettingsActive.backgroundColor
      : ButtonSettings.backgroundColor};
  color: ${(p) =>
    p.isdisabled
      ? ButtonSettings.borderColor
      : p.type === "danger"
      ? ButtonSettingsDanger.fontColor
      : p.type === "success"
      ? ButtonSettingsSuccess.fontColor
      : p.isactive
      ? ButtonSettingsActive.fontColor
      : ButtonSettings.fontColor};
  width: ${(p) => (p.isfluid ? "unset" : "fit-content")};
  cursor: ${(p) => (p.isdisabled ? "default" : "pointer")};
  transition: all 0.2s ease-in-out;
  pointer-events: ${(p) => (p.isdisabled ? "none" : "auto")};
  &:hover {
    border: 1px solid
      ${(p) =>
        p.type === "danger"
          ? ButtonSettingsHoverDanger.borderColor
          : p.type === "success"
          ? ButtonSettingsHoverSuccess.borderColor
          : ButtonSettingsHover.borderColor};
    background: ${(p) =>
      p.type === "danger"
        ? ButtonSettingsHoverDanger.backgroundColor
        : p.type === "success"
        ? ButtonSettingsHoverSuccess.backgroundColor
        : ButtonSettingsHover.backgroundColor};
    color: ${(p) =>
      p.type === "danger"
        ? ButtonSettingsHoverDanger.fontColor
        : p.type === "success"
        ? ButtonSettingsHoverSuccess.fontColor
        : ButtonSettingsHover.fontColor};
  }
  &:active {
    border: 1px solid
      ${(p) =>
        p.type === "danger"
          ? ButtonSettingsActiveDanger.borderColor
          : p.type === "success"
          ? ButtonSettingsActiveSuccess.borderColor
          : ButtonSettingsActive.borderColor};
    background: ${(p) =>
      p.type === "danger"
        ? ButtonSettingsActiveDanger.backgroundColor
        : p.type === "success"
        ? ButtonSettingsActiveSuccess.backgroundColor
        : ButtonSettingsActive.backgroundColor};
    color: ${(p) =>
      p.type === "danger"
        ? ButtonSettingsActiveDanger.fontColor
        : p.type === "success"
        ? ButtonSettingsActiveSuccess.fontColor
        : ButtonSettingsActive.fontColor};
  }
`;

export const ConfirmBtnContainer = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  column-gap: 3px;
`;
