import * as React from "react";
import { ConteantHeadline } from "../../services/config";
import { FiHome } from "react-icons/fi";

interface HomeProps {}

const Home = (p: HomeProps) => {
  return (
    <>
      <ConteantHeadline>
        Subbr-Dubbr Turnier Mänädsching Tool v1
        <FiHome
          style={{ float: "left", marginTop: "0.6em", marginRight: 10 }}
        />
      </ConteantHeadline>
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. <br />
        At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
        gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
      </p>
      <img src="https://picsum.photos/500/300.jpg" alt="demoimage"/>
    </>
  );
};
export default Home;
