import { createButtonColorStyle, hexToRgba } from "./helpers";
import styled from 'styled-components';

export type SitesTypes = "home" | "icons" | "database";


export const BasicSettings = {
    accentColor: "#3F8CDC",
    accentColorDanger: "#950014",
    accentColorSuccess: "#009514",
    contentBackgroundColor: "#FFFFFF",
    contentFontColor: "#313639",
    sidebarBackgroundColor: "#F8F8F8",
    sidebarFontColor: "#313639",
}

// color, borderColorAlpha, backgroundColorAlpha, fontColorAlpha
export const ButtonSettings = createButtonColorStyle(BasicSettings.contentFontColor, 6, 3, 100);
export const ButtonSettingsHover = createButtonColorStyle(BasicSettings.accentColor, 40, 5, 100);
export const ButtonSettingsActive = createButtonColorStyle(BasicSettings.accentColor, 100, 15, 100);

export const ButtonSettingsDanger = createButtonColorStyle(BasicSettings.accentColorDanger, 20, 10, 100);
export const ButtonSettingsHoverDanger = createButtonColorStyle(BasicSettings.accentColorDanger, 60, 20, 100);
export const ButtonSettingsActiveDanger = createButtonColorStyle(BasicSettings.accentColorDanger, 100, 30, 100);

export const ButtonSettingsSuccess = createButtonColorStyle(BasicSettings.accentColorSuccess, 20, 10, 100);
export const ButtonSettingsHoverSuccess = createButtonColorStyle(BasicSettings.accentColorSuccess, 60, 20, 100);
export const ButtonSettingsActiveSuccess = createButtonColorStyle(BasicSettings.accentColorSuccess, 100, 30, 100);



export const SidebarDragSettings = {
    // sidebarDragBackground: `linear-gradient(90deg, #F8F8F8 60%, ${hexToRgba(BasicSettings.sidebarFontColor, 30)} 100%)`,
    // sidebarDragBackgroundHover: `linear-gradient(90deg, #F8F8F8 60%, ${hexToRgba(BasicSettings.accentColor, 60)} 100%)`,
     sidebarDragBackgroundHover: hexToRgba(BasicSettings.sidebarFontColor, 10),
    sidebarDragBackground: BasicSettings.sidebarBackgroundColor,
    // sidebarDragBackgroundHover: BasicSettings.sidebarBackgroundColor,
    sidebarDragShadowColor: hexToRgba(BasicSettings.sidebarFontColor, 60),
}

export const ConteantHeadline = styled.div`
    font-size: 1.5em;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid ${hexToRgba(BasicSettings.sidebarFontColor, 30)};
    line-height: 50px;
    margin-bottom: 20px;
    font-weight: 600;
    svg{
        stroke: ${BasicSettings.accentColor};
    }
`;



