import styled from "styled-components";
import { ButtonSettings, ButtonSettingsActive } from "../../services/config";

export const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(150px, max-content) minmax(
      150px,
      max-content
    );
  gap: 5px;
  input {
    outline: none;
    font-family: "Inconsolata", Tahoma, Verdana, sans-serif;
    line-height: 40px;
    padding: 0 10px;
    border: 1px solid ${ButtonSettings.borderColor};
    &:focus {
      background: ${(p) => ButtonSettingsActive.backgroundColor};
    }
  }
`;
export const Text = styled.div`
  line-height: 20px;
  border: 1px solid ${ButtonSettings.borderColor};
  padding: 10px;
  position: relative;
`;
export const Id = styled.div`
  position: absolute;
  right: 3px;
  bottom: 3px;
  font-size: 10px;
  line-height: 10px;
  color: #cfcfcf;
`;
export const AddContainer = styled.div`
  grid-column-start: 2;
  grid-column-end: 4;
  pre {
    background: ${(p) => ButtonSettings.backgroundColor};
    padding: 20px;
    border: 1px solid ${(p) => ButtonSettings.borderColor};
  }
`;
export const Spacer = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  height: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${(p) => ButtonSettingsActive.backgroundColor};
`;
