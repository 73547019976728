import * as React from "react";
import { FiCheck, FiX } from "react-icons/fi";
import { ConfirmBtnContainer, Container } from "./ButtonSC";

interface ButtonProps {
  text?: string;
  isfluid?: boolean;
  isactive?: boolean;
  confirm?: boolean;
  onClick?: () => void;
  icon?: JSX.Element;
  floated?: "left" | "right";
  isdisabled?: boolean;
}

const Button = (p: ButtonProps) => {
  const {
    isdisabled,
    confirm,
    text,
    isfluid,
    onClick,
    isactive,
    icon,
    floated,
  } = p;
  const [confirmDelete, setConfirmdelete] = React.useState<boolean>(false);

  const toggleConfirm = React.useMemo(
    () => () => {
      setConfirmdelete(!confirmDelete);
    },
    [confirmDelete]
  );

  return (
    <>
      {confirm ? (
        <>
          {confirmDelete ? (
            <ConfirmBtnContainer>
              <Container
                isfluid={true}
                onClick={toggleConfirm}
                isactive={true}
                iconOnly
                type="danger"
              >
                <FiX style={{ float: "left", marginTop: 12 }} />
              </Container>
              <Container
                isfluid={true}
                onClick={onClick}
                isactive={false}
                floated={"right"}
                type="success"
              >
                <FiCheck
                  style={{ float: "left", marginTop: 12, marginRight: 10 }}
                />
                OK
              </Container>
            </ConfirmBtnContainer>
          ) : (
            <Container
              isfluid={isfluid ?? false}
              onClick={toggleConfirm}
              isactive={isactive ?? false}
              floated={floated}
            >
              {icon}
              {text}
            </Container>
          )}
        </>
      ) : (
        <Container
          isfluid={isfluid ?? false}
          onClick={isdisabled ? undefined : onClick}
          isactive={isactive ?? false}
          floated={floated}
          isdisabled={isdisabled}
        >
          {icon}
          {text}
        </Container>
      )}
    </>
  );
};
export default Button;
