import { ConteantHeadline } from "../../services/config";
import { FiCoffee } from "react-icons/fi";

interface IconsProps {}

const Icons = (p: IconsProps) => {
  return (
    <>
      <ConteantHeadline>
        Icons Bibliothek{" "}
        <FiCoffee
          style={{ float: "left", marginTop: "0.6em", marginRight: 10 }}
        />
      </ConteantHeadline>
      <iframe
        src="https://react-icons.github.io/react-icons/icons?name=fi"
        style={{ width: "100%", height: "100%", border: "none" }}
        title="icons"
      />
    </>
  );
};
export default Icons;
