export function hexToRgba(hex: string, alpha: number): string {
  hex = hex.replace(/^#/, "");

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  const alphaDecimal = alpha / 100;

  if (alphaDecimal < 0 || alphaDecimal > 1) {
    console.error("Der Alpha-Wert muss im Bereich von 0 bis 100 liegen.");
    return "rgba(0, 0, 0, 1)";
  }

  return `rgba(${r}, ${g}, ${b}, ${alphaDecimal})`;
}

export function createButtonColorStyle(
  color: string,
  borderColorAlpha: number,
  backgroundColorAlpha: number,
  fontColorAlpha: number
) {
  return {
    borderColor: hexToRgba(color, borderColorAlpha),
    backgroundColor: hexToRgba(color, backgroundColorAlpha),
    fontColor: hexToRgba(color, fontColorAlpha),
  };
}
