import Mainview from "./components/Mainview/Mainview";

function App() {
  return (
    <>
      <Mainview />
    </>
  );
}

export default App;
