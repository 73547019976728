import * as React from "react";
import { FiCoffee, FiDatabase, FiHome } from "react-icons/fi";
import { SitesTypes } from "../../services/config";
import Database from "../../views/Database/Database";
import Home from "../../views/Home/Home";
import Icons from "../../views/Icons/Icons";
import Button from "../Button/Button";
import { BiSun, BiSolidSun } from "react-icons/bi";

import {
  ButtonGrid,
  ContentContainer,
  DragLineContainer,
  MainBody,
  SidebarContainer,
  SidebarContainerBottom,
} from "./MainViewSC";

interface MainviewProps {}

const Mainview = (p: MainviewProps) => {
  const [xPosition, setXPosition] = React.useState<number>(300);
  const [isDragging, setIsDragging] = React.useState<boolean>(false);
  const [darkMode, setDarkmode] = React.useState<boolean>(false);
  const [hue, setHue] = React.useState<number>(0);
  const [brightness, setBrightness] = React.useState<number>(100);
  const [content, setContent] = React.useState<SitesTypes>("home");

  const dragLineRef = React.createRef<HTMLDivElement>();

  const changeHue = React.useMemo(() => (h: number) => {
    setHue(h);
  },[]);

  const changeBrightness = React.useMemo(() => (b: number) => {
    setBrightness(b);
  },[]);

  const handleDoubleClick = React.useMemo(
    () => () => {
      setXPosition(300);
    },
    []
  );

  React.useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      if (e.target === dragLineRef.current) {
        setIsDragging(true);
      }
    };
    const handleMouseUp = (e: MouseEvent) => {
      setIsDragging(false);
    };
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        setXPosition(e.clientX);
      }
    };
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isDragging, dragLineRef]);

  return (
    <MainBody sidebarwidth={xPosition} darkMode={darkMode} hue={hue} brightness={brightness}>
      <SidebarContainer>
        <div style={{position: "relative", height: 120}}>
          <img src="logo512.png" style={{position: "absolute", left: "50%", transform: "translateX(-50%)", width: 100}} alt="logo"/>
        </div>

        <ButtonGrid>
          <Button
            text="Home"
            isfluid
            onClick={() => setContent("home")}
            isactive={content === "home"}
            icon={
              <FiHome
                style={{ float: "left", marginTop: 12, marginRight: 10 }}
              />
            }
          />
          <Button
            text="Icons"
            isfluid
            onClick={() => setContent("icons")}
            isactive={content === "icons"}
            icon={
              <FiCoffee
                style={{ float: "left", marginTop: 12, marginRight: 10 }}
              />
            }
          />
          <Button
            text="Datenbank Test"
            isfluid
            onClick={() => setContent("database")}
            isactive={content === "database"}
            icon={
              <FiDatabase
                style={{ float: "left", marginTop: 12, marginRight: 10 }}
              />
            }
          />
        </ButtonGrid>
        <SidebarContainerBottom>
        <Button
            onClick={() => setDarkmode(!darkMode)}
            floated="left"
            isactive={content === "database"}
            icon={
              darkMode ? <BiSolidSun
                style={{ float: "left", marginTop: 12 }}
              /> : <BiSun
              style={{ float: "left", marginTop: 12 }}
            />
            }
          />
          <input type="range" min={0} max={360} value={hue} style={{float: "right", marginTop: 0}} onChange={(e) => changeHue(parseInt(e.target.value))} onDoubleClick={() => changeHue(0)}/>
          <input type="range" min={0} max={300} step={10} value={brightness} style={{float: "right", marginTop: 0}} onChange={(e) => changeBrightness(parseInt(e.target.value))} onDoubleClick={() => changeBrightness(100)}/>
          </SidebarContainerBottom>
      </SidebarContainer>
      <DragLineContainer ref={dragLineRef} onDoubleClick={handleDoubleClick} />
      <ContentContainer>
        {content === "home" && <Home />}
        {content === "icons" && <Icons />}
        {content === "database" && <Database />}
      </ContentContainer>
    </MainBody>
  );
};
export default Mainview;
