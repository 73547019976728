import styled from "styled-components";
import { BasicSettings, SidebarDragSettings } from "../../services/config";

export const MainBody = styled.div<{ sidebarwidth: number, darkMode: boolean, brightness: number,  hue: number }>`

img{
    filter: none !important;
  }

  margin: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: ${(p) => p.sidebarwidth}px 15px 1fr;
  font-family: "Inconsolata", Tahoma, Verdana, sans-serif;
  font-size: 14px;
  filter: invert(${p => p.darkMode ? 0.95 : 0}) hue-rotate(${p => p.hue}deg) brightness(${p => p.brightness}%);
  transition: all 0.2s ease-in-out;
`;
export const SidebarContainer = styled.div`
  background: ${BasicSettings.sidebarBackgroundColor};
  color: ${BasicSettings.sidebarFontColor};
  padding: 10px 5px 10px 15px;
  position: relative;
`;
export const SidebarContainerBottom = styled.div`
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: calc(100% - 10px);
`;
export const DragLineContainer = styled.div`
  background: blue;
  background: ${SidebarDragSettings.sidebarDragBackground};
  cursor: col-resize;
//   box-shadow: 6px 0px 9px -6px ${SidebarDragSettings.sidebarDragShadowColor};
//   transition: all 0.2s ease-in-out 0.2s;
  z-index: 10;
  &:hover {
    // background: ${SidebarDragSettings.sidebarDragBackgroundHover};
  }
`;
export const ContentContainer = styled.div`
  background: ${BasicSettings.contentBackgroundColor};
  padding: 0px 20px 20px 40px;
  z-index: 1;
`;
export const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  row-gap: 5px;
`;
