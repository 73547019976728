import * as React from "react";
import {
  FiEdit,
  FiEye,
  FiEyeOff,
  FiPlusSquare,
  FiSave,
  FiTrash2,
} from "react-icons/fi";
import Button from "../../components/Button/Button";
import { TableContainer, AddContainer, Spacer, Id, Text } from "./DatabaseSC";

interface TestDBProps {}
interface TestDBEntry {
  id: number;

  text: string;
}

const TestDB = (p: TestDBProps) => {
  const [newEntry, setNewEntry] = React.useState("");
  const [entries, setEntries] = React.useState<TestDBEntry[]>([]);
  const [editingEntry, setEditingEntry] = React.useState<{
    id: number;
    text: string;
  } | null>({ id: -1, text: "" });
  const [showJson, setShowJson] = React.useState<boolean>(false);

  React.useEffect(() => {
    fetch("./entries.json")
      .then((response) => response.json())
      .then((data: TestDBEntry[]) => setEntries(data));
  }, []);

  const fetchEntries = () => {
    fetch("./entries.json")
      .then((response) => response.json())
      .then((data: TestDBEntry[]) => setEntries(data))
      .catch((error) => console.error("Fehler beim Abrufen der Daten:", error));
  };

  const addEntry = () => {
    const newEntryObj: TestDBEntry = { id: Date.now(), text: newEntry };
    setEntries([...entries, newEntryObj]);
    setNewEntry("");

    fetch("./entries.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([...entries, newEntryObj]),
    });
  };

  const editEntry = (id: number, newText: string) => {
    const updatedEntries = entries.map((entry) =>
      entry.id === id ? { ...entry, text: newText } : entry
    );
    setEntries(updatedEntries);
    setEditingEntry(null);

    fetch("./entries.json", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedEntries),
    }).then(() => {
      fetchEntries(); // Daten erneut abrufen
    });
  };

  const deleteEntry = (id: number) => {
    const updatedEntries = entries.filter((entry) => entry.id !== id);
    setEntries(updatedEntries);
    console.log("DELETE");
    fetch("./entries.json", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedEntries),
    }).then(() => {
      fetchEntries(); // Daten erneut abrufen
    });
  };

  return (
    <>
      <TableContainer>
        <div>
          <b>
            Die Daten liegen in der entries.json, werden aber nicht aktualisiert
            !!!
          </b>
        </div>
        <AddContainer>
          <Button
            isactive={showJson}
            text={showJson ? "Verstecke die Json" : "Zeig mir die Json"}
            isfluid
            onClick={() => setShowJson(!showJson)}
            icon={
              showJson ? (
                <FiEyeOff
                  style={{ float: "left", marginTop: 13, marginRight: 10 }}
                />
              ) : (
                <FiEye
                  style={{ float: "left", marginTop: 13, marginRight: 10 }}
                />
              )
            }
          />
        </AddContainer>
        <AddContainer style={{ gridColumnStart: 1 }}>
          {showJson && (
            <pre>
              <code>{JSON.stringify(entries, null, 2)}</code>
            </pre>
          )}
        </AddContainer>
        <Spacer />
        {editingEntry &&
          entries.map((entry) =>
            editingEntry.id === entry.id ? (
              <React.Fragment key={entry.id}>
                <input
                  type="text"
                  value={editingEntry.text}
                  onChange={(e) =>
                    setEditingEntry({ id: entry.id, text: e.target.value })
                  }
                  autoFocus
                />
                <AddContainer>
                  <Button
                    text="Speichern"
                    isfluid
                    onClick={() => editEntry(entry.id, editingEntry.text)}
                    icon={
                      <FiSave
                        style={{
                          float: "left",
                          marginTop: 13,
                          marginRight: 10,
                        }}
                      />
                    }
                  />
                </AddContainer>
              </React.Fragment>
            ) : (
              <React.Fragment key={entry.id}>
                <Text>
                  {entry.text}
                  <Id>id: #{entry.id}</Id>
                </Text>
                <Button
                  text="Edit"
                  isfluid
                  onClick={() =>
                    setEditingEntry({ id: entry.id, text: entry.text })
                  }
                  icon={
                    <FiEdit
                      style={{ float: "left", marginTop: 13, marginRight: 10 }}
                    />
                  }
                />
                <Button
                  confirm
                  text="Löschen"
                  isfluid
                  onClick={() => deleteEntry(entry.id)}
                  icon={
                    <FiTrash2
                      style={{ float: "left", marginTop: 14, marginRight: 10 }}
                    />
                  }
                />
              </React.Fragment>
            )
          )}

        <Spacer />

        <input
          type="text"
          placeholder="Neuer Eintrag"
          value={newEntry}
          onChange={(e) => setNewEntry(e.target.value)}
        />
        <AddContainer>
          <Button
            text="Hinzufügen"
            isdisabled={!newEntry}
            isfluid
            onClick={addEntry}
            icon={
              <FiPlusSquare
                style={{ float: "left", marginTop: 13, marginRight: 10 }}
              />
            }
          />
        </AddContainer>
      </TableContainer>
    </>
  );
};
export default TestDB;
